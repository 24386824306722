// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-test-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\contact-test.js" /* webpackChunkName: "component---src-pages-contact-test-js" */),
  "component---src-pages-contact-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-blog-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\new-blog.js" /* webpackChunkName: "component---src-pages-new-blog-js" */),
  "component---src-pages-portfolio-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-programming-close-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\programming\\close.js" /* webpackChunkName: "component---src-pages-programming-close-js" */),
  "component---src-pages-services-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-story-components-character-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\story\\components\\Character.js" /* webpackChunkName: "component---src-pages-story-components-character-js" */),
  "component---src-pages-story-index-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\story\\index.js" /* webpackChunkName: "component---src-pages-story-index-js" */),
  "component---src-pages-websites-js": () => import("E:\\Documents\\Websites\\ronda-lunn\\src\\pages\\websites.js" /* webpackChunkName: "component---src-pages-websites-js" */)
}

